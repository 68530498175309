import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Button, Form, List, Input, Modal, ActionSheet, Toast } from 'antd-mobile'
import { setUser } from '@/redux/actions/user'
import { registerApi, LoginApi, resetPasswordApi, logoutApi } from '@/services/user'
import './UserAccount.css'
import { dayDiff, isSafari, twoTimeInterval } from '@/utils/common'
import cookie from 'react-cookies'

const UserAccount = function (props) {
  const { user, setUser } = props

  const [visible, setVisible] = React.useState(false)
  const [loginVisible, setLoginVisible] = React.useState(false)
  const [registerVisible, setRegisterVisible] = React.useState(false)
  const [remainTime, setRemainTime] = React.useState('')
  const [forgetPasswordVisible, setForgetPasswordVisible] = React.useState(false)

  const loginFormRef = React.useRef(null)
  const registerFormRef = React.useRef(null)
  const forgetPasswordFormRef = React.useRef(null)

  function getRemainTime() {
    const now = new Date().format('yyyy-MM-dd hh:mm:ss')
    return twoTimeInterval(now, user.vip_end_time)
  }

  useEffect(() => {
    if (user.is_vip) {
      setRemainTime(getRemainTime())
      const interval = window.setInterval(() => setRemainTime(getRemainTime()), 1000)
      return () => window.clearInterval(interval)
    }
  }, [user.is_vip])

  const handleAction = (e) => {
    if (e.key === 'login') {
      setLoginVisible(true)
    } else if (e.key === 'register') {
      setRegisterVisible(true)
    } else if (e.key === 'forgetPassword') {
      if (user.account) {
        setForgetPasswordVisible(true)
      } else {
        Toast.show({ icon: 'fail', content: '当前未登陆账号' })
      }
    } else if (e.key === 'logout') {
      if (user.account) {
        handleLogout()
      } else {
        Toast.show({ icon: 'fail', content: '当前未登陆账号' })
      }
    }
    setVisible(false)
  }

  const handleLogin = async (values) => {
    const { username, password } = values
    const { msg, data, code } = await LoginApi(username, password)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    Toast.show({
      icon: 'success',
      content: '登陆成功',
    })
    // 删除is_debug的标识
    if (cookie.load('is_debug')) {
      cookie.remove('is_debug')
    }
    setUser(data)
    setLoginVisible(false)
    loginFormRef.current?.resetFields()
    window.location.reload()
  }

  const handleRegister = async (values) => {
    const { username, password, password1 } = values
    if (password !== password1) {
      Toast.show({
        icon: 'fail',
        content: '两次输入的密码不一致',
      })
      return
    }
    const { data, msg, code } = await registerApi(username, password)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    Toast.show({
      icon: 'success',
      content: '注册成功',
    })
    setUser(data)
    setRegisterVisible(false)
    registerFormRef.current?.resetFields()
  }

  const handleForgetPassword = async (values) => {
    const { password, password1 } = values
    if (password !== password1) {
      Toast.show({
        icon: 'fail',
        content: '两次输入的密码不一致',
      })
      return
    }
    const { msg, code } = await resetPasswordApi(password)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    Toast.show({
      icon: 'success',
      content: '修改密码成功',
    })
    setForgetPasswordVisible(false)
    forgetPasswordFormRef.current?.resetFields()
  }

  async function handleLogout() {
    if (!user.account) {
      Toast.show({
        icon: 'fail',
        content: '当前没有登录账号',
      })
    } else {
      const { code } = await logoutApi()
      if (code !== 200) return
      Toast.show({
        icon: 'success',
        content: '已退出账号',
      })
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  const usernameRules = [
    { required: true },
    {
      pattern: /^[\u4e00-\u9fa5a-zA-Z0-9@]{1,20}$/,
      message: '用户名只能包含中文、大小写字母、和数字，长度是1-20位',
    },
  ]
  const passwordRules = [{ required: true }, { type: 'string', min: 4, max: 20 }]

  return (
    <>
      <List.Item
        style={{ textDecoration: 'none', lineHeight: '40px' }}
        onClick={() => {
          setVisible(true)
        }}
        extra={
          <div>
            <div style={{ textAlign: 'right' }}>{user.account ? user.account.username : '未注册'}</div>
            {user.is_vip && (
              <div
                style={{
                  textAlign: 'right',
                  fontSize: 10,
                  lineHeight: '6px',
                }}>
                已激活VIP，剩余&nbsp;
                <span style={{ color: remainTime.indexOf('时') === -1 ? 'red' : 'inherit' }}>{remainTime}</span>
              </div>
            )}
          </div>
        }>
        <span style={{ color: 'var(--theme-text-color)' }}>账号</span>
      </List.Item>

      <ActionSheet
        popupClassName="popup-wrapper"
        visible={visible}
        actions={[
          { text: '登陆账号', key: 'login', bold: true },
          { text: '注册账号', key: 'register' },
          { text: '修改密码', key: 'forgetPassword' },
          { text: '退出登录', key: 'logout' },
        ]}
        onClose={() => setVisible(false)}
        onAction={handleAction}
      />

      <Modal
        title="登陆账号"
        visible={loginVisible}
        closeOnMaskClick
        onClose={() => setLoginVisible(false)}
        content={
          <div>
            {!user.account && (
              <div style={{ fontSize: 10, color: 'var(--adm-color-danger)', marginBottom: 20 }}>
                <div>当前是游客账号，登录账号会导致当前账号无法找回，如需保存当前账号信息请先注册账号！</div>
              </div>
            )}

            <Form
              initialValues={{ username: '', password: '' }}
              ref={loginFormRef}
              onFinish={handleLogin}
              footer={
                <Button block type="submit" color="primary" size="large">
                  登陆
                </Button>
              }>
              <Form.Item name="username" label="用户名" rules={usernameRules}>
                <Input placeholder="请输入用户名" />
              </Form.Item>
              <Form.Item name="password" label="密码" rules={passwordRules}>
                <Input placeholder="请输入密码" type="password" />
              </Form.Item>
            </Form>
          </div>
        }
      />

      <Modal
        title="注册账号"
        visible={registerVisible}
        closeOnMaskClick
        onClose={() => setRegisterVisible(false)}
        content={
          <div>
            {!user.account && (
              <div style={{ fontSize: 10, color: 'var(--adm-color-danger)', marginBottom: 20 }}>
                <div>注册账号后将保留当前账号信息，请妥善保管账号密码</div>
              </div>
            )}
            <Form
              initialValues={{ username: '', password: '', password1: '' }}
              ref={registerFormRef}
              onFinish={handleRegister}
              footer={
                <Button block type="submit" color="primary" size="large">
                  注册
                </Button>
              }>
              <Form.Item name="username" label="用户名" rules={usernameRules}>
                <Input placeholder="请输入用户名" />
              </Form.Item>
              <Form.Item name="password" label="密码" rules={passwordRules}>
                <Input placeholder="请输入密码" type="password" />
              </Form.Item>
              <Form.Item name="password1" label="确认密码" rules={passwordRules}>
                <Input placeholder="请确认密码" type="password" />
              </Form.Item>
            </Form>
          </div>
        }
      />

      <Modal
        title="修改密码"
        visible={forgetPasswordVisible}
        closeOnMaskClick
        onClose={() => setForgetPasswordVisible(false)}
        content={
          <Form
            initialValues={{ password: '', password1: '' }}
            ref={forgetPasswordFormRef}
            onFinish={handleForgetPassword}
            footer={
              <Button block type="submit" color="primary" size="large">
                修改
              </Button>
            }>
            <Form.Item name="password" label="密码" rules={passwordRules}>
              <Input placeholder="请输入密码" type="password" />
            </Form.Item>
            <Form.Item name="password1" label="确认密码" rules={passwordRules}>
              <Input placeholder="请确认密码" type="password" />
            </Form.Item>
          </Form>
        }
      />
    </>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

function mapDispatchProps(dispatch) {
  return {
    setUser: (user) => dispatch(setUser(user)),
  }
}

export default connect(mapStateToProps, mapDispatchProps)(UserAccount)
