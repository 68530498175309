import React from 'react'
import { List } from 'antd-mobile'

export default function Feedback(props) {
  const { openFeedback } = props

  function hanldleClick() {
    openFeedback()
  }

  return (
    <>
      <List.Item style={{ textDecoration: 'none', lineHeight: '40px' }} onClick={hanldleClick}>
        <span style={{ color: 'var(--theme-text-color)' }}>
          <span>联系客服（请直接留言建议或问题）</span>
        </span>
      </List.Item>
    </>
  )
}
