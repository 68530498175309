import React, { useEffect, useState, useRef } from 'react'
import { Form, List, Input, Toast, Button, Image, Modal, Divider } from 'antd-mobile'
import EditDrawer from './EditDrawer'
import './ActiveAccount.css'
import { getVerifyCodeApi } from '@/services/base'
import { sendSMSApi } from '@/services/user'
import { activeByPhoneApi, activeByWxApi, loginByWxApi, loginByPhoneApi } from '@/services/active'
import wxQRCodeImg from '@/assets/qrcode.jpg'
import czbzImg from '@/assets/czbz.jpg'

export default function ActiveAccount(props) {
  const { user, bind } = props
  const [open, setOpen] = useState(false)

  const [isLoginMode, setIsLoginMode] = useState(false)

  const [phoneVisible, setPhoneVisible] = useState(false)
  const [verifyCodeImg, setVerifyCodeImg] = useState('')
  const verifyCodeIdRef = useRef(null)
  const phoneFormRef = useRef(null)

  const [wxVisible, setWxVisible] = useState(false)
  const codeRef = useRef(null)

  useEffect(() => {}, [])

  async function handleWxFinish() {
    const activeCode = codeRef.current?.nativeElement.value || null
    if (!activeCode) {
      Toast.show({ icon: 'fail', content: '请先输入从微信公众号获取的 激活码' })
      return
    }
    if (!isLoginMode) {
      const { code, msg } = await activeByWxApi(activeCode)
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      Toast.show({
        icon: 'success',
        content: '绑定激活成功，即将自动刷新',
      })
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } else {
      // 微信登录
      const { msg, code } = await loginByWxApi(activeCode)
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      Toast.show({
        icon: 'success',
        content: '登陆成功',
      })
      window.location.reload()
    }
  }

  useEffect(() => {
    if (phoneVisible) {
      getVerifyCode()
    }
  }, [phoneVisible])

  async function handleSendSMSCode() {
    if (phoneFormRef.current) {
      const phone = phoneFormRef.current.getFieldValue('phone')
      if (!(phone && /^1[3-9]\d{9}$/.test(phone))) {
        Toast.show({ icon: 'fail', content: '请先输入有效的手机号' })
        return
      }
      const verifyCode = phoneFormRef.current.getFieldValue('verifyCode')
      if (!verifyCode) {
        Toast.show({ icon: 'fail', content: '请先输入图片验证码' })
        return
      }
      // 调发短信验证码接口
      let target = isLoginMode ? 'login' : 'register'
      const resp = await sendSMSApi(phone, target, verifyCode, verifyCodeIdRef.current)
      if (resp.code === 200) {
        Toast.show({ icon: 'success', content: '短信验证码已发送' })
      } else {
        Toast.show({ icon: 'fail', content: resp.msg })
      }
    }
  }

  async function handlePhoneFinish(values) {
    const { phone, smsCode } = values
    if (!isLoginMode) {
      const { msg, code } = await activeByPhoneApi(phone, smsCode)
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      Toast.show({
        icon: 'success',
        content: '绑定激活成功，即将自动刷新',
      })
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } else {
      // 手机号登录
      const { msg, code } = await loginByPhoneApi(phone, smsCode)
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      Toast.show({
        icon: 'success',
        content: '登陆成功',
      })
      window.location.reload()
    }
  }

  async function getVerifyCode() {
    const { data, code, msg } = await getVerifyCodeApi()
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    setVerifyCodeImg(data.image)
    verifyCodeIdRef.current = data.id
  }

  const handleSave = () => {
    setOpen(false)
  }

  function handleClose() {
    setOpen(false)
  }

  const phoneRules = [
    { required: true },
    {
      pattern: /^1[3-9]\d{9}$/,
      message: '请输入有效的手机号',
    },
  ]
  const smsCodeRules = [{ required: true, message: '请输入短信验证码' }]

  return (
    <>
      <Modal
        title={isLoginMode ? '微信登录' : '绑定微信激活'}
        visible={wxVisible}
        closeOnMaskClick
        onClose={() => {
          setWxVisible(false)
        }}
        content={
          <div style={{ textAlign: 'center' }}>
            <div style={{ color: 'var(--theme-text-color)', fontSize: 12, marginTop: 10 }}>
              <div>请关注下方“侃侃谈笑”微信公众号</div>
              <div>输入“激活码”后领取</div>
            </div>
            <div style={{ display: 'inline-block', marginTop: 6 }}>
              <Image alt="img" src={wxQRCodeImg} width={100} />
            </div>

            <div>
              <div
                style={{
                  padding: 10,
                  border: '1px solid',
                  borderColor: 'var(--border)',
                  marginBottom: '20px',
                }}>
                <Input ref={codeRef} placeholder="请输入获取的激活码" />
              </div>

              <Button color="primary" onClick={handleWxFinish} style={{ width: '100px' }}>
                确定
              </Button>
            </div>
          </div>
        }
      />
      <Modal
        title={isLoginMode ? '手机号登录' : '绑定手机号激活'}
        visible={phoneVisible}
        closeOnMaskClick
        onClose={() => {
          setPhoneVisible(false)
        }}
        content={
          <div>
            <Form
              onFinish={handlePhoneFinish}
              initialValues={{ phone: '', password: '', password1: '' }}
              ref={phoneFormRef}
              footer={
                <Button block type="submit" color="primary">
                  确定
                </Button>
              }>
              <Form.Item name="phone" label="手机号" rules={phoneRules}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Input placeholder="请输入手机号" />
                </div>
              </Form.Item>
              <Form.Item label="" name="verifyCode">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Input placeholder="请输入" />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Image
                      alt="img"
                      style={{ cursor: 'pointer', width: 108 }}
                      src={'data:image/png;base64,' + verifyCodeImg}
                      height={36}
                      onClick={getVerifyCode}
                    />
                    <Button
                      style={{ userSelect: 'none', fontSize: '10px', padding: '0 0 0 6px', width: '30px' }}
                      color="primary"
                      fill="none"
                      size="mini"
                      onClick={handleSendSMSCode}>
                      发验证码
                    </Button>
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="smsCode" label="短信验证码" rules={smsCodeRules}>
                <Input placeholder="请输入短信验证码" />
              </Form.Item>
            </Form>
          </div>
        }
      />

      <List.Item
        style={{ textDecoration: 'none', lineHeight: '40px' }}
        onClick={() => setOpen(true)}
        extra={user && user.id && <div>{user.user_type === 'try' ? '试用用户' : '正式用户'}</div>}>
        <span style={{ color: 'var(--theme-text-color)' }}>绑定激活</span>
      </List.Item>

      {open && (
        <EditDrawer
          height="50%"
          onClickSave={handleSave}
          title="绑定激活"
          anchor="bottom"
          open={open}
          onClose={handleClose}>
          <div style={{ padding: '0px 16px' }}>
            <div style={{ fontSize: '10px', color: 'red', marginBottom: '10px', textAlign: 'center' }}>
              绑定账号仅用于更有效的封禁违规用户，创造良好的聊天氛围。选择以下任意方式即可激活，我们将妥善保护您的隐私
            </div>
            <div className="info-item-wrapper">
              <div className="info-item">
                <span className="label">手机号</span>
                {bind?.phone && <span className="value">{bind?.phone || ''}</span>}
              </div>
              {!bind?.phone && (
                <Button
                  size="small"
                  color="primary"
                  fill="outline"
                  onClick={() => {
                    setOpen(false)
                    setPhoneVisible(true)
                  }}
                  style={{ width: 104 }}>
                  手机号激活
                </Button>
              )}
            </div>
            <div className="info-item-wrapper">
              <div className="info-item">
                <span className="label">微信标识</span>
                {bind?.wx_openid && <span className="value">{bind?.wx_openid || ''}</span>}
              </div>
              {!bind?.wx_openid && (
                <Button
                  size="small"
                  color="primary"
                  fill="outline"
                  disabled={bind?.wx_openid}
                  style={{ width: 104 }}
                  onClick={() => {
                    setOpen(false)
                    setWxVisible(true)
                  }}>
                  微信激活
                </Button>
              )}
            </div>

            <div>
              <div style={{ fontSize: '10px', margin: '60px 0px 20px 0px', textAlign: 'center' }}>
                已绑定的账号可通过对应的方式直接登录
              </div>
              <div className="login-btns">
                <Button
                  onClick={() => {
                    setOpen(false)
                    setIsLoginMode(true)
                    setPhoneVisible(true)
                  }}
                  color="primary"
                  style={{ width: 120 }}>
                  手机号登录
                </Button>
                <Button
                  color="primary"
                  style={{ width: 120 }}
                  onClick={() => {
                    setOpen(false)
                    setIsLoginMode(true)
                    setWxVisible(true)
                  }}>
                  微信登录
                </Button>
              </div>
            </div>
          </div>
        </EditDrawer>
      )}
    </>
  )
}
