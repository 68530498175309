import React, { useState } from 'react'
import { Dialog, Input, List, Toast } from 'antd-mobile'
import { destroyUsersApi } from '@/services/user'

export default function DestroyAccount(props) {
  const [value, setValue] = useState('')
  const [loadingVisible, setLoadingVisible] = React.useState(false)

  async function handleAction(e) {
    if (e.key === 'destroy') {
      if (value !== '我确认注销该账号') {
        Toast.show({ icon: 'fail', content: '请输入 我确认注销该账号 后点击 确认注销', duration: 3000 })
        return
      }
      const { code, msg } = await destroyUsersApi()
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      Toast.show({ icon: 'success', content: '已为您注销该账号，谢谢您对本平台的支持，欢迎再来', duration: 5000 })
      setTimeout(() => {
        window.location.reload()
      }, 5000)
    }
    setLoadingVisible(false)
  }

  async function hanldleDestroyAccount() {
    setLoadingVisible(true)
  }

  return (
    <>
      <Dialog
        visible={loadingVisible}
        onAction={handleAction}
        actions={[
          [
            {
              key: 'cancel',
              text: '取消',
            },
            {
              key: 'destroy',
              text: '确认注销',
              bold: true,
              danger: true,
            },
          ],
        ]}
        title="确认注销账号吗？"
        content={
          <div>
            <span>注销账号即可生效，聊天数据及账号信息都将从服务器删除，该操作无法恢复，请谨慎操作！！</span>
            <Input
              style={{
                marginTop: '6px',
              }}
              placeholder="请输入 我确认注销该账号"
              value={value}
              onChange={(val) => {
                setValue(val)
              }}
            />
          </div>
        }
        closeOnMaskClick
        onClose={() => {
          setLoadingVisible(false)
        }}
        bodyStyle={{ opacity: 0.9 }}
      />

      <List.Item style={{ textDecoration: 'none', lineHeight: '40px' }} onClick={hanldleDestroyAccount}>
        <span style={{ color: 'var(--theme-text-color)' }}>
          <span>注销账号</span>
        </span>
      </List.Item>
    </>
  )
}
